import {
    keyframes,
    Link,
    Box,
    Heading,
    Text,
    Button
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Countdown from 'react-countdown';

const UnwrapPage = () => {
    const metaTitle = "Unwrap your NFT Christmas present";
    const metaDescription = "From Midnight UTC on 24th December, unwrap your NFT Christmas present";
    const twitterName = "";

    const fadeIn = keyframes`
    from {opacity: 0;}
    to {opacity: 1}
  `;

  const disabledFadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 0.4}
`;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href="https://nftsanta.com/unwrap" />
                <meta name="icon" href="../images/nft-present.png" />
                <script defer data-domain="nftysanta.com" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>

            <Box
                pos="absolute"
                height="100%"
                minH="100vh"
                width="100%"
                bgGradient="linear(to-b, black, gray.800)"
                zIndex={-1}
            >
                <StaticImage
                    src="../images/bauble.jpg"
                    alt=""
                    loading="eager"
                    layout="fullWidth"
                    style={{
                        opacity: "0.4",
                        height: "100%",
                    }}
                    formats={["auto", "webp", "avif"]}
                />
            </Box>

            <Box pt={36} pl={8} color="white" maxW="800px">
                <Text
                    opacity={0}
                    animation={`${fadeIn} 1.5s ease 1s forwards`}
                >Countdown to Christmas</Text>
                <Heading
                    opacity={0}
                    animation={`${fadeIn} 1.5s ease 1s forwards`}
                    fontSize={{ base: "48px", md: "78px" }}
                    as="h1"
                    textTransform="uppercase"
                    fontWeight="500"
                    letterSpacing="5px"
                >
                    <Countdown date={1640390400*1000} />
                </Heading>

                <Box opacity={0} animation={`${fadeIn} 1.5s ease 2s forwards`}>
                    <StaticImage
                        src="../images/nft-present.png"
                        alt="NFT Christmas Present"
                        loading="eager"
                        placeholder="blurred"
                        width={180}
                        formats={["auto", "webp", "avif"]}
                        style={{
                            display: "inline-block",
                        }}
                    />
                </Box>

                <Button
                    variant="outline"
                    animation={`${disabledFadeIn} 1.5s ease 2s forwards`}
                    isDisabled
                    _disabled={{
                        opacity: 0,
                        cursor: "not-allowed"
                    }}
                    _hover={{}}
                >
                    Unwrap on UTC Christmas Day
                </Button>


                <Text
                    mt={16}
                    opacity={0}
                    animation={`${fadeIn} 1.5s ease 2s forwards`}
                    fontFamily="mono"
                    fontSize={{ base: "8px", md: "12px" }}
                >
                    Built by{" "}
                    <Link href={`https://twitter.com/${twitterName}`} isExternal>
                        @{twitterName}
                    </Link>{" "}
                    <br />
                    Contract address at {" "}
                    <Link href={`https://etherscan.io/address/${process.env.GATSBY_CONTRACT_ADDRESS}`} isExternal>
                        {process.env.GATSBY_CONTRACT_ADDRESS}
                    </Link>
                </Text>
            </Box>
        </>
    );
};

export default UnwrapPage;
